@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home {
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("./images/HomePage.JPG");
  background-position: center;
  background-size: cover;
  color: #fff;
  background-repeat: no-repeat;
}

.donate {
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./images/Donate.jpg");
  background-position: center;
  background-size: cover;
  color: #fff;
  background-repeat: no-repeat;
}

.gear {
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./images/Gear.jpg");
  background-position: center;
  background-size: cover;
  color: #fff;
  background-repeat: no-repeat;
}

.subHome, .subGear {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 3rem;
  font-weight: 500;
  font-family: "PT Sans", sans-serif;
  height: 90vh;
  padding-bottom: 2rem;
}

.animate-bounce {
  animation: bounce 1s infinite !important;
}

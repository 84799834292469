.dropbtn {
  color: white;
  /* color: black; */
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.drop-btn-blk {
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: whitesmoke;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  margin: 0.5rem;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  margin-top: 0.5rem;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #252831;
}

.nav_logo {
  height: 5rem;
}

.MuiAppBar-colorSecondary {
  background: rgba(0, 0, 0, 0) !important;
}

.navDisplay {
  display: flex;
  justify-content: space-between;
  float: right;
  color: #252831;
}

.MuiContainer-root {
  margin: 0 !important;
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

.Muipaper {
  border-bottom: none !important;
}
